import { Popover, TableProps } from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import React, { Dispatch, FC, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";

import { CallProps } from "../../../../api/CallApi/Call";
import { UpdateClient } from "../../../../api/ClientApi/Client";
import { UserLight } from "../../../../assets";
import { useUser } from "../../../../context/auth-context";
import { Colors } from "../../../../utils/colors";
import { period } from "../../../../utils/helpers/period";
import { timer } from "../../../../utils/helpers/timer";
import TableStyled from "../../../Table";
import { FilterType } from "../ListReportsScreen";

import { IdWrapper, NameWrapper } from "./ListReportsTable.styled";

export interface DataClientType {
  key: React.Key;
  call_id: number;
  name: string;
  from_phone: number;
  duration_sec: number;
  claim_id: number;
  call_dt_create: string;
  verify: number;
  is_success: boolean;
  enroll: string;
  is_fraud: boolean;
  age: string;
  gender: string;
  ethnicity: string;
}

type TableStyledProps = {
  data: CallProps[];
  onClick?: () => void;
  setOffset?: (val: number) => void;
  filterValues: any;
  total: number;
  offset?: number;
  limit?: number;
  filter?: Dispatch<SetStateAction<FilterType>>;
  onUpdate?: (clientUpdate: UpdateClient, id: string) => void;
  onDelete?: (value: string) => void;
};
const ListReportsTable: FC<TableStyledProps> = ({
  data,
  setOffset,
  offset,
  limit = 10,
  filter,
  total,
}) => {
  const navigate = useNavigate();
  const { config } = useUser();

  const columns: ColumnsType<DataClientType> = [
    {
      title: "Call ID",
      dataIndex: "call_id",
      render: (callId) => (
        <NameWrapper>
          <Popover content={<span>{callId}</span>} title="Call ID">
            <IdWrapper
              style={{ cursor: "pointer" }}
              onClick={() => navigate(`/call/${callId}`)}
            >
              {callId}
            </IdWrapper>
          </Popover>
        </NameWrapper>
      ),
      sorter: {},
    },
    {
      title: "Agent Full Name",
      dataIndex: "name",
      render: (arr) => (
        <a
          style={{ color: `${Colors.TITLE_SILVER_COLOR}` }}
          onClick={() => navigate(`/user-profile/${arr[1]}`)}
        >
          <NameWrapper>
            <div>
              <UserLight />
            </div>
            <span style={{ whiteSpace: "nowrap" }}>{arr[0]}</span>
          </NameWrapper>
        </a>
      ),
    },
    {
      title: "Phone",
      dataIndex: "from_phone",
      render: (phone) => <span>{phone}</span>,
      sorter: {},
    },
    {
      title: <span style={{ whiteSpace: "nowrap" }}>Call duration</span>,
      dataIndex: "duration_sec",
      render: (duration) => <span>{timer(duration)}</span>,
      sorter: {},
    },
    {
      title: "Claim id",
      dataIndex: "claim_id",
      render: (arr) => (
        <NameWrapper
          style={{ cursor: "pointer" }}
          onClick={() => navigate(`/client-profile/${arr[1]}`)}
        >
          <span>{arr[0]}</span>
        </NameWrapper>
      ),
      sorter: {},
    },
    {
      defaultSortOrder: "descend",
      title: "Call Date",
      sorter: {},
      dataIndex: "call_dt_create",
      render: (callDate) =>
        callDate && (
          <span style={{ whiteSpace: "nowrap" }}>
            {moment(callDate).format("DD[/]MM[/]YYYY HH:mm")}
          </span>
        ),
    },
    {
      title: config?.can.debug ? "Verification score" : "Verification",
      dataIndex: "verify",
      render: (arr) =>
        arr[0] ? (
          <span>
            {config?.can.debug
              ? `${+arr[0].toFixed(4)}`
              : arr[0] < arr[1]
              ? `${
                  Math.round(Math.min(arr[0] / arr[1], 0.98) * 100) < 0
                    ? 0
                    : Math.round(Math.min(arr[0] / arr[1], 0.98) * 100)
                }%`
              : "98%"}
          </span>
        ) : null,
    },
    {
      title: "Verification result",
      dataIndex: "is_success",
      render: (verify) =>
        verify !== undefined ? (
          <span style={{ whiteSpace: "nowrap" }}>
            {verify ? "Verified" : "Not verified"}
          </span>
        ) : null,
    },
    {
      title: "Enroll result",
      dataIndex: "enroll",
      render: (enroll) =>
        enroll !== undefined ? (
          <span style={{ whiteSpace: "nowrap" }}>
            {enroll === "finished" ? "Enrolled" : "Failed"}
          </span>
        ) : null,
    },
    {
      title: <span style={{ whiteSpace: "nowrap" }}>Fraud result</span>,
      dataIndex: "is_fraud",
      render: (isFraud) =>
        isFraud !== undefined ? (
          <span style={{ whiteSpace: "nowrap" }}>
            {isFraud ? "Fraud" : "Not fraud"}
          </span>
        ) : null,
      // sorter: {},
    },
    {
      title: <span style={{ whiteSpace: "nowrap" }}>Fraud score</span>,
      dataIndex: "fraud_score",
      render: (score: number) => (
        <span style={{ whiteSpace: "nowrap" }}>{score}</span>
      ),
    },
    {
      title: "Age",
      dataIndex: "age",
      // sorter: {},
      render: (age) => (
        <span style={{ whiteSpace: "nowrap" }}>{period(age)}</span>
      ),
    },
    {
      title: "Gender",
      dataIndex: "gender",
      // sorter: {},
      render: (gender) => <span>{gender}</span>,
    },
    {
      title: "Ethnicity",
      dataIndex: "ethnicity",
      // sorter: {},
      render: (ethnicity) => <span>{ethnicity}</span>,
    },
  ];

  const dataParsers = (data: CallProps[]) => {
    return data.map((columnData) => {
      return {
        key: columnData.call_id,
        call_id: columnData.call_id,
        name: [columnData?.operator_name, columnData.operator_id],
        from_phone: columnData.from_phone,
        duration_sec: columnData.duration_sec,
        claim_id: [columnData?.claim_id, columnData.client_id],
        call_dt_create: columnData?.call_dt_create,
        verify: [
          columnData?.verify?.[0]?.actual_score,
          columnData?.verify?.[0]?.target_score,
        ],
        is_success: columnData?.verify?.[0]?.["is_success"],
        is_fraud: columnData?.fraud?.[0]?.is_spoof,
        fraud_score: columnData?.fraud?.[0]?.score,
        age: columnData?.age?.[0]?.age,
        gender: columnData?.gender?.[0]?.gender,
        ethnicity: columnData?.ethnicity?.[0]?.ethnicity,
        enroll: columnData.enroll?.[0].task_status,
      };
    });
  };

  const onChange: TableProps<DataClientType>["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    if (sorter?.order && sorter?.field) {
      const field = sorter?.order
        ? sorter.order.substr(0, 3) === "asc"
          ? sorter.order.substr(0, 3)
          : sorter.order.substr(0, 4)
        : "";
      filter?.((prev) => ({
        ...prev,
        ["sort"]: sorter.field,
        ["dir"]: field,
        export: null,
      }));
    }
  };

  return (
    <TableStyled
      limit={limit}
      offset={offset}
      setOffset={setOffset}
      total={total}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      columns={columns}
      dataSource={dataParsers(data)}
      onChange={onChange}
    />
  );
};

export default ListReportsTable;
